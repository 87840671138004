import axios from 'axios';
import cookieCutter from 'cookie-cutter';

const BASE_URL = '/api/proxy';

function getProxyURL(baseURL, endpoint) {
  if (baseURL != null) {
    return `${baseURL}${endpoint}`;
  } else {
    return `${BASE_URL}${endpoint}`;
  }
}

export async function clientApi(
  endpoint,
  method = 'GET',
  params = null,
  body = null,
  headers = {},
  responseType = null,
  baseURL,
) {
  const options = {
    method,
    url: getProxyURL(baseURL, endpoint),
    params,
    data: body,
    responseType: responseType,
    headers: {
      'Content-Type': 'application/json',
      'X-UserSession': `${cookieCutter.get('user_session_id') ? cookieCutter.get('user_session_id') : ''}`,
      ...headers,
    },
  };
  try {
    return await axios(options);
  } catch (error) {
    if (
      error.response.status == 401 &&
      error?.response?.data?.detail == 'profile status changed.'
    ) {
      return await axios(options);
    } else if (
      error.response.status == 401 &&
      (error?.response?.data?.detail == 'Not authenticated' ||
        error?.response?.data?.detail == 'Unauthorized')
    ) {
      window.location.href = '/login?refresh=true';
    } else if (error.response.status == 500) {
      throw new Error(error);
    } else {
      return error.response;
    }
  }
}
